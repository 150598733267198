// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/*** Bouton  ***/
a.btn-link {
    margin-top: 50px;
    background: $color-1;
    width: 255px;
    height: 75px;
    border-radius: 10px;
    transition: 0.3s;
    .icon {
        position: relative;
        img {
                                        
            &:nth-child(1) {
                opacity: 1;
                transition: 0.3s;
            }
            &:nth-child(2) {
                position: absolute;
                opacity: 0;
                left: 0;
                bottom: 0;
                transition: 0.3s;
            }
        }
    }
    .text {
        padding-top: 5px;
        h5 {
            font-weight: 600;
            font-size: $font-size-16;
            color: #3D4251;
            text-transform: uppercase;
            transition: 0.3s;
        }
    }
    &:hover {
        background: #3D4251;
        transition: 0.3s;
        img {
            &:nth-child(1) {
                opacity: 0;
                transition: 0.3s;
            }
            &:nth-child(2) {
                opacity: 1;
                transition: 0.3s;
            }
        }
        .text h5 {
            color: #74B0BD;
            transition: 0.3s;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        width: 180px;
        .text {
            padding-top: 2px;
        }
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';




/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-text {
    padding: 75px 7% 180px 7%;
    background: #E8F0F0;
    h4 {
        font-weight: normal;
        font-size: $font-size-40;
        color: #324F4D;
        line-height: 1.3;
        width: 81%;
    }
    .right-side {
        h3 {
            font-weight: bold;
            font-size: $font-size-70;
            color: #8EB7B4;
            text-transform: initial;
            padding-bottom: 5px;
        }
        h5 {
            font-weight: normal;
            font-size: $font-size-26;
            color: #8EB7B4;
        }
    }
    @media screen and (max-width: 1800px) {
        h4 {
            width: 86%;
        }
        @media screen and (max-width: 1610px) {
            h4 {
                width: 95%;
            }
            @media screen and (max-width: 1560px) {
                h4 {
                    font-size: 1.75rem;
                    width: 98%;
                }
                @media screen and (max-width: $size-md-max) {
                    h4 {
                        width: 100%;
                        font-size: 1.7rem;
                    }
                    @media screen and (max-width: 1200px) {
                        flex-direction: column;
                        align-items: initial;
                        h4 {
                            &.pb6 {
                                padding-bottom: 1rem;
                            }
                        }
                        .left-side {
                            padding-bottom: 1rem;
                        }
                        @media screen and (max-width: $size-xs-max) {
                            padding: 60px 5% 150px 5%;
                            h4 {
                                &.pb6 {
                                    padding-bottom: 1.5rem;
                                }
                            }
                            .left-side {
                                padding-bottom: 1.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-images {
    padding: 25px 7% 50px 7%;
    margin-top: -125px;
    .section-images.accordion {
        font-size: 0;
        height: 100%;
        .image.accordion__item {
            font-size: 1rem;
            display: inline-block;
            vertical-align: top;
            height: 100%;
            min-height: 720px;
            box-sizing: border-box;
            position: relative;
            outline: none;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 720px;
            &:nth-child(1) {
                .img-background {
                    background-image: url("../images/accueil_section02_img01.jpg");
                    background-position: center;
                    background-size: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            &:nth-child(2) {
                .img-background {
                    background-image: url("../images/accueil_section02_img02.jpg");
                    background-position: center;
                    background-size: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            &:nth-child(3) {
                .img-background {
                    background-image: url("../images/accueil_section02_img03.jpg");
                    background-position: center;
                    background-size: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            div.txt-box {
                padding-top: 15px;
                h2 {
                    font-weight: 600;
                    font-size: $font-size-20;
                    color: #0F1818;
                    text-transform: uppercase;
                }
                p {
                    font-size: $font-size-20;
                    color: #324F4D;
                }
            }
        }
    }
    @media screen and (max-width: 1210px) {
        .slideshow-wrap {
            padding-top: 0;
        }
    }
}

#section-logo {
    padding: 100px 0;
    margin: 0 5% 25px 5%;
    h3 {
        font-weight: bold;
        font-size: $font-size-50;
        color: $color-3;
        text-align: center;
        padding-bottom: 75px;
    }
    .bloc-logo {
        p {
            font-size: 18px;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 75px 0;
        h3 {
            padding-bottom: 50px;
        }
        .bloc-logo {
            flex-wrap: wrap;
            .logo {
                width: 50%;
                padding: 15px;
            }
        }
    }
}

/*******************************************************************************
* À PROPOS
*******************************************************************************/

#section-intro {
    padding: 100px 5%;
    text-align: center;
    h2 {
        font-weight: 600;
        font-size: $font-size-90;
        color: #0F1818;
        padding-bottom: 15px;
        letter-spacing: 3.48px;
    }
    p {
        width: 66%;
        font-weight: 300;
        font-size: $font-size-22;
        margin: auto;
        color: #0F1818;
    }
    a.btn-link {
        margin: 30px auto 0 auto;
    }
    @media screen and (max-width: 1600px) {
        p {
            width: 80%;
        }
        @media screen and (max-width: $size-md-max) {
            p {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                padding: 75px 5%;
                h2 {
                    font-size: 3.8rem;
                    line-height: 1;
                }
            }
        }
    }
    &.services {
        margin-bottom: 50px;
        p {
            width: 59%;
        }
        @media screen and (max-width: 1645px) {
            p {
                width: 80%;
            }
            @media screen and (max-width: $size-md-max) {
                p {
                    width: 100%;
                }
                @media screen and (max-width: $size-sm-max) {
                    h2 br {
                        display: none;
                    }

                    @media screen and (max-width: $size-xs-max) {
                        padding: 75px 5%;
                        margin-bottom: 65px;
                        h2 {
                            font-size: 3.8rem;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }
    &.associations {
        h2 {
            font-size: $font-size-60;
        }
        p {
            width: 56%;
        }
        @media screen and (max-width: 1652px) {
            h2 br {
                display: none;
            }
            @media screen and (max-width: 1600px) {
                p {
                    width: 67%;
                }
                @media screen and (max-width: 1550px) {
                    h2 {
                        font-size: 2.6rem;
                    }
                    @media screen and (max-width: $size-md-max) {
                        p {
                            width: 100%;
                        }
                        @media screen and (max-width: 1024px) {
                            h2 {
                                font-size: 2.1rem;
                            }
                            @media screen and (max-width: $size-sm-max) {
                                h2 {
                                    font-size: 2.5rem;
                                }
                                @media screen and (max-width: $size-xs-max) {
                                    padding: 75px 5%;
                                    h2 {
                                        font-size: 2.1rem;
                                        line-height: 1;
                                    }
                                    @media screen and (max-width: 375px) {
                                        h2 {
                                            font-size: 1.95rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-image {
    margin-bottom: 35px;
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-text-background {
    background: #E8F0F0;
    padding: 100px 7%;
    margin: 75px 0 25px 0;
    text-align: center;
    h3 {
        font-weight: bold;
        font-size: $font-size-30;
        color: #121D1D;
        line-height: 1.5; 
    }
    @media screen and (max-width: 1536px) {
        h3 br {
            display: none;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 75px 5%;
            margin: 0 0 25px 0;
            @media screen and (max-width: 360px) {
                h3 {
                    font-size: 1.4rem;
                }
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

section#toolbar {
    padding: 0 7%;
    ul.menu-filter {
        list-style: none;
        padding-left: 0;
        li.filter-item {
            a {
                //background: transparent;
                //border: none;
                //border-radius: 10px;
                font-weight: 700;
                font-size: $font-size-18;
                color: #0F1818;
                text-transform: uppercase;
                cursor: pointer;
                transition: all 0.3s ease-out;
                &:hover {
                    color: $color-1;
                    transition: all 0.3s ease-out;
                }
            }
            span {
                font-size: 18px;
                font-weight: normal;
                padding-left: 15px;
            }
            &:last-child span {
                display: none;
            }
        }
        li.filter-item:nth-child(even) {
            margin: 0 15px;
        }
    }
    @media screen and (max-width: $size-sm-max) {
        ul.menu-filter li.filter-item button {
            width: initial;
        }
        @media screen and (max-width: $size-xs-max) {
            ul.menu-filter {
                flex-direction: column;
                align-items: center;
                li.filter-item span {
                    display: none;
                }
                li.filter-item:nth-child(2) {
                    margin: 0;
                }
            }
        }
    }
}

section.gallerie-products {
    padding: 50px 7% 100px 7%;


    .section-gallerie {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .grid-item {
            padding-bottom: 35px;
            margin: 7px;
            width: 32%;
            min-width: 250px;
            img {
                width: 100%;
            }
        }

        .header-gallery-section {
            width: 100%;
            flex: none;

            h4 {
                    font-size: $font-size-30;
                    color: #0F1818!important;
                    font-weight: 700;
            }
        }

        @media screen and (max-width: $size-md-max){
            .grid-item {
                width: 45%;
            }
        }
        @media screen and (max-width: 600px){
            flex-direction: column;
            justify-content: center;
        }

        .grid-item {
            .section-title {
                padding-top: 20px;

                h4 {
                    font-weight: normal;
                    font-size: $font-size-20;
                    color: #324f4d;
                }
            }
        }
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 50px 5% 75px 5%;
    }
}

/*******************************************************************************
* ASSOCIATIONS
*******************************************************************************/

#section-association {
    padding: 75px 7%;
    .section-text {
        border-bottom: 1px solid #5D918D53;
        padding-bottom: 35px;
        margin-bottom: 50px;

        h3 {
            font-weight: bold;
            font-size: $font-size-50;
            color: #0F1818;
            padding-bottom: 5px;
        }
        p {
            line-height: 1.3;
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 75px 7% 50px 7%;

        .section-logo {
            flex-wrap: wrap;
            justify-content: initial;
            .logo {
                width: 32%;
                padding: 25px;
                margin: auto;
                .img-responsive {
                    margin: auto;
                }
            }
        }


        @media screen and (max-width: $size-sm-max) {
            .section-text {
                p {
                    br {
                        display: none;
                    }
                }
            }


            @media screen and (max-width: $size-xs-max) {
                padding: 75px 5% 50px 5%;
                .section-text {
                    margin-bottom: 25px;
                }
                .section-logo {
                    .logo {
                        width: 50%;
                    }
                }
            }

        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 0 7% 100px 7%;
    background: #B9D7DE2F;
    .form-info {
        position: relative;
        margin-top: -205px;
    }
    .section-form {
        width: 65%;
        margin-right: 15px;
        background: #E8F0F0;
        padding: 65px 100px 50px 100px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #324F4D;
            padding-bottom: 5px;
        }
        p {
            font-weight: 300;
            font-size: $font-size-20;
            color: #0F1818;
            line-height: 1.1;
            padding-bottom: 25px;
        }
        .form-group {
            margin-bottom: -15px;
        }
    }
    .section-info {
        width: 35%;
        padding: 65px 45px 35px 45px;
        background: #588B88;
        h2 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #D5E7EB;
            text-transform: uppercase;
        }
        h3 {
            font-weight: bold;
            font-size: $font-size-26;
            color: #D5E7EB;
            text-transform: initial;
            padding-bottom: 5px;
        }
        .section-tel {
            padding-top: 75px;
            h4 {
                font-weight: 600;
                font-size: $font-size-18;
                color: $color-1;
            }
        }
        .section-fax {
            padding: 25px 0;
        }
        .section-address {
            padding: 0 0 25px 0;
        }
        .section-tel, .section-fax, .section-address {
            h5 {
                font-weight: normal;
                font-size: $font-size-18;
                color: #D5E7EB;
            }
        }
        .jours-heures {
            .jour {
                width: 43%;
            }
            h4 {    
                font-weight: bold;
                font-size: $font-size-18;
                color: #D5E7EB;
                line-height: 1.8;
            }
            h5 {
                font-weight: normal;
                font-size: $font-size-18;
                color: #D5E7EB;
                line-height: 1.8;
            }
        }
    }
    @media screen and (max-width: $size-md-max) {
        .form-info {
            flex-direction: column-reverse;
            margin-top: -140px !important;
            .section-info {
                width: 70%;
                margin: auto;
                .jours-heures .jour {
                    width: 18%;
                }
            }
            .section-form {
                width: 85%;
                margin: 35px auto 0 auto;
            }
        }
        @media screen and (max-width: 1024px) {
            .form-info {
                .section-info {
                    width: 75%;
                    padding: 65px 45px;
                    .section-tel {
                        padding-top: 70px;
                    }
                }
                .section-form {
                    width: 100%;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .form-info {
                    margin-top: -110px !important;
                    .section-info {
                        width: 90%;
                        .jours-heures .jour {
                            width: 32%;
                        }
                    }
                    .section-form {
                        padding: 65px 50px 50px 50px;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 0 5% 100px 5%;
                    .form-info {
                        .section-info {
                            width: 100%;
                            padding: 50px 25px;
                            .jours-heures .jour {
                                width: 32%;
                            }
                            h2 {
                                text-align: center;
                            }
                        }
                        .section-form {
                            padding: 50px 25px;
                            .form-group.col-xs-12.col-sm-6.pl0 {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-map {
    padding: 50px 7%;
    @media screen and (max-width: $size-xs-max) {
        padding: 50px 5%;
        #map-canvas {
            height: 500px !important
        }
    }
}

div#content {
    h3#firstHeading {
        font-size: $font-size-28;
        padding-bottom: 10px;
        color: $color-1;
    }
    p a {
        color: #0F1818;
    }
}

/*******************************************************************************
* EMPLOIS
*******************************************************************************/

#section-text-emploi {
    padding: 50px 0 35px 0;
    border-bottom: 1px solid #588B88;
    margin: 0 7%;
    h4 {
        font-weight: normal;
        font-size: $font-size-26;
        color: #0F1818;
        line-height: 1.3; 
    }
    @media screen and (max-width: $size-xs-max){
        margin: 0 5%;
        h4 br {
            display: none;
        }
    }
}

#section-emploi {
    padding: 75px 5% 0 5%;
    div.section-wrapper {
        display: inline-grid;
        margin: auto;
        width: 90%;
        max-width: 1600px;;
        justify-items: stretch;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        @media screen and (max-width: $size-sm-max){
            grid-template-columns: 1fr;
        }
    }
    div.left-side, div.right-side {
        width: 50%;
        @media screen and (max-width: $size-xs-max){
          width: 100%;
        }
    }

    section.emplois-liste {
        display: flex;
        flex-wrap: wrap;
        div.item-poste {
            display: inline-block;
            margin-bottom: 20px;
            background-color: #F2F2F2;
            transition: .5s;
            width: 49%;

            div.title {
                background-color: #588B88;
                padding: 30px 7%;
                h4 {
                    color: #F2F2F2;
                    font-size: 20px;
                    font-weight: bold;
                    text-transform: initial;
                }
                @media screen and (max-width: 1325px){
                    padding: 30px 5%;
                }
            }
            div.soustitre {
                padding: 30px 7%;
                .icon {
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: #203E45;
                    margin-right: 50px;
                    padding-bottom: 10px;
                    .fa {
                        font-size: 20px;
                        margin-right: 10px;
                        color: $color-1;
                    }
                    @media screen and (max-width: 1420px){
                        margin-right: 25px;
                        @media screen and (max-width: 1270px){
                            margin-right: 0;
                            width: 100%;
                            margin-bottom: 15px;
                            @media screen and (max-width: 1110px){
                                margin-right: 25px;
                                width: initial;
                                margin-bottom: 0;
                                @media screen and (max-width: $size-xs-max){
                                    margin-right: 0;
                                    width: 100%;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1325px){
                    padding: 30px 5%;
                }
            }
            div.content-full {
                padding: 0 7% 30px 7%;
                h4, h3, h2 {
                    font-size: 18px;
                    color: #203E45;
                    padding: 15px 0;
                    font-weight: bold;
                }
                p, li {
                    color: #203E45;
                    font-weight: normal;
                    font-size: 16px;
                    padding-left: 25px;
                    line-height: 2;
                    @media screen and (max-width: 1420px){
                        padding-left: 0;
                        @media screen and (max-width: $size-xs-max){
                            line-height: 1.5;
                        }
                    }
                }
                .btn-wrapper {
                    max-width: 145px;
                    margin-top: 50px;
                    .btn {
                        padding: 20px 5px;
                        background-color: $color-1;
                        border: none;
                        color: #F2F2F2;
                        border-radius: 0;
                        font-weight: bold;
                        font-size: 16px;
                        &:hover {
                            border: none;
                            opacity: 0.7;
                            background: #3D4251;
                            color: #74B0BD;
                        }
                    }
                }
                @media screen and (max-width: 1325px){
                    padding: 0 5% 30px 5%;
                }
            }
            &:nth-child(odd) {
                margin-right: 20px;
                @media screen and (max-width: 1110px){
                    margin-right: 0;
                }
            }
            @media screen and (max-width: 1110px){
                width: 100%;
                margin: 0 0 15px 0;
            }
        }
    }
}

section.section-form-emploi {
    padding: 60px 10% 100px 10%;
    background-color: #E8F0F045;
    h3 {
        font-weight: bold;
        color: #3D4251;
        font-size: $font-size-30;
        padding-bottom: 20px;
        @media screen and (max-width: 360px) {
            font-size: 1.4rem;
        }
    }
    .form-group {
        margin-bottom: -10px;
    }
    input {
        height: 95px;
        border: 1px solid #A6ADB4;
    }
    textarea#message {
        border: 1px solid #A6ADB4;
    }
    textarea::placeholder {
        padding: 15px 5px !important;
    }
    form label.filedoc {
        background: $color-1;
        width: 200px;
        color: #F2F2F2;
        font-weight: bold;
        font-size: 16px;
        &:hover {
            background: #3D4251;
            color: #74B0BD;
            opacity: 0.7;
        }
    }
    .btn {
        border-radius: 0;
        border: none;
        color: #F2F2F2;
        background: $color-1;
        font-weight: bold;
        font-size: 16px;
        padding: 20px;
        &:hover {
            border: none;
            opacity: 0.7;
            background: #3D4251;
            color: #74B0BD;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .pl0 , .pr0, {
            padding: 0!important;
        }
        form label.filedoc {
            width: 230px;
            margin-right: 0;
        }
        form .buttons {
            justify-content: center;
            margin: 0 0 50px 0;
        }
        .btn-submit {
            margin-top: 15px;
            .btn.submit-emploi {
                width: 230px;
            }
        }
    }
    @media screen and (max-width: 1325px){
        padding: 60px 8% 25px 8%;
        @media screen and (max-width: $size-sm-max){
            padding: 60px 5% 25px 5%;
        }
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
   


}

.slideshow-wrap {
    position: relative;
    
    .caption {
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;
        width: 100%;
        padding: 0 7%;

        .contenu {
            .left-side {
                width: 100%;
                margin-right: 50px;
                h2 {
                    font-weight: 600;
                    font-size: $font-size-80;
                    color: $color-white;
                    padding-bottom: 20px;
                    text-transform: uppercase;
                    line-height: 1;
                    //text-shadow: 0 0 10px $color-black;
                }
                p {
                    font-weight: normal;
                    color: $color-white;
                    width: 62%;
                    line-height: 1.3;
                    //text-shadow: 0 0 10px $color-black;
                }
            }

            a.scroll-link {
                padding-top: 6%;
                .mouse {
                    max-width: 2.5rem;
                    width: 100%;
                    height: auto;
                }
                .scroll {
                    animation-name: scroll;
                    animation-duration: 1.5s;
                    animation-timing-function: cubic-bezier(0.650, -0.550, 0.250, 1.500);
                    animation-iteration-count: infinite;
                    transform-origin: 50% 20.5px;
                    will-change: transform, opacity;
                    opacity: 1;
                }
                .scroll-link {
                    position: absolute;
                    bottom: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                }
                @keyframes scroll {
                    0%, 20% {
                        transform: translateY(0) scaleY(1);
                    }
                    100% {
                        transform: translateY(36px) scaleY(2);
                        opacity: 0;
                    }
                }
            }
        }
        @media screen and (max-width: 1750px) {
            
            @media screen and (max-width: 1490px) {
                .contenu {
                    .left-side {
                        h2 {
                            font-size: 3.5rem;
                        }
                    }
                }
                @media screen and (max-width: 1165px) {
                    .contenu {
                        .left-side {
                            width: 100%;
                            margin-right: 0;
                            h2 {
                                font-size: 3.2rem;
                            }
                            p {
                                font-size: 1rem;
                            }
                        }
                    }
                    a.scroll-link {
                        display: none;
                    }
                    @media screen and (max-width: 1024px) {
                        top: 42%;
                        a.tel {
                            top: -55%;
                        }
                        @media screen and (max-width: $size-sm-max) {
                            top: 40%;
                            .contenu {
                                .left-side {
                                    h2 {
                                        font-size: 2.5rem;
                                    }
                                    p {
                                        width: 70%;
                                    }
                                }
                            }
                            @media screen and (max-width: $size-xs-max) {
                                top: 45%;
                                padding: 0 5%;
                                .contenu {
                                    .left-side {
                                        h2 {
                                            font-size: 3rem;
                                            br {
                                                display: none;
                                            }
                                        }
                                        p {
                                            width: 100%;
                                        }
                                    }
                                }
                                a.tel {
                                    top: -85%;
                                }
                                @media screen and (max-width: 375px) {
                                    .contenu {
                                        .left-side {
                                            h2 {
                                                font-size: 2.5rem;
                                            }
                                        }
                                    }
                                    @media screen and (max-width: 360px) {
                                        top: 39%;
                                        a.tel {
                                            top: -60%;
                                        }
                                    } 
                                } 
                            }
                        }
                    }
                }
            }
        }
    }

    a.tel {
        position: absolute;
        right: 7%;
        top: -80%;
    }

    .slideshow {
        width: 100%;
        overflow: hidden;
        position: relative;
        .placeholder {
          width: 100vw;
          height: auto;
          display: block;
            @media screen and (max-width: $size-slider-breakpoint){
                height: calc(100vw / 480 * 600);
            }
        }
        &.mobile {
            display: none;
        }
        .slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;
                .layer-inner {
                    left: 0;
                    right: 0;
                    bottom: 20%;
                    padding: 20px;
                    position: absolute;
                    background: rgba(0,0,0, 0.5);

                    h2 {
                        font-weight: 900;
                        text-align: center;
                        font-size: $font-size-46;
                        color: $color-white;
                        @media screen and (max-width: $size-sm-max) {
                            font-size: 2rem;
                        }
                    }
                }
            }
          img {
            width: 100vw!important;
            height: auto;
            display: block;
            position: relative;
          }
            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
          }
          &.active {
              right: 0;
                left: 0;
              z-index: 10;
                opacity: 1;
                transition: 1.5s;
          }
        }
        @media screen and (max-width: 480px) {
            &.mobile {
                display: block;
            }
            &.ecran {
                display: none;
            }
        }
    }
}

.btn {
    background-color: $color-1;
    color: #3D4251;
    border: none;
    border-radius: 10px;
    padding: 28.5px;
    font-weight: bold;
    font-size: $font-size-18;
    letter-spacing: 3.6px;
    width: 100%;
    transition: all 0.2s ease-in;

    &:hover {
        transition: all 0.2s ease-in;
        background-color: #3D4251;
        color: $color-1;
    }
}

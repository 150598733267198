footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        padding: 0 5%;
        justify-content: space-between;
        align-items: initial;
        @extend .flex;
        @extend .flex-wrap;
        @extend .text-color-grey;
        @extend .font-footer;

        .copyright-logo {
            .copyright {
                font-weight: 600;
                font-size: $font-size-16;
                color: #CACBD5;
                margin-bottom: 10px;
            }
        }
        
        .section-info {
            h4 {
                font-weight: 600;
                font-size: $font-size-26;
                color: #B9D7DE;
                padding-bottom: 10px;
            }
            h5 {
                font-weight: normal;
                font-size: $font-size-20;
                color: #D5E7EB;
            }
        }
        @media screen and (max-width: 1585px) {
            .section-reseau {
                width: 100%;
                margin-top: 35px;
            }
            @media screen and (max-width: 1400px) {
                justify-content: initial;
                align-items: initial;
                height: initial;
                .logo {
                    width: 100%;
                    border-right: none;
                    padding: 0;
                    margin: 0 0 30px 0;
                }
                .copyright-logo {
                    width: 100%;
                    margin: 0 0 50px 0;
                }
                .section-info {
                    margin-right: 0;
                    width: 100%;
                    margin: 0 0 30px 0;
                }
                @media screen and (max-width: $size-xs-max) {
                    .copyright-logo {
                        margin: 0 0 35px 0;
                    }
                    .section-info {
                        flex-direction: column;
                        .center-side {
                            margin: 15px 0;
                        }
                    }
                }
            }    
        }              
    }
    @media screen and (max-width: 1400px) {
        height: 500px;
        @media screen and (max-width: $size-xs-max) {
            height: 625px;
        }
    }
}
